import { ThemeUIStyleObject } from 'theme-ui';
import { BrandGalleryVariant } from './types';
import { useContainerPaddingReturn } from '@sprinklr/shared-lib/hooks/useContainerPadding';

export const brandVariantStyles: Record<
  BrandGalleryVariant,
  ThemeUIStyleObject
> = {
  DEFAULT: {
    flexWrap: 'wrap',
    justifyContent: 'center',
    '>*': {
      justifyContent: 'center',
      mx: [3, 4, 4, '40px'],
      my: [3, 4, 4, 4],
      flex: ['1 0 120px', '1 0 140px', '1 0 155px'],
      textAlign: 'center',
      maxHeight: '100px',
      maxWidth: ['60px', '120px', '165px'],
    },
  },
  CONDENSED: {
    flexWrap: 'wrap',
    justifyContent: 'center',
    '>*': {
      justifyContent: 'center',
      mx: [3, 4, 4, '40px'],
      flex: ['1 0 120px', '1 0 140px', '1 0 155px'],
      textAlign: 'center',
      maxHeight: '100px',
      maxWidth: ['60px', '120px', '165px'],
    },
  },
  BRAND_BELT: {
    mt: [3, null, null, 0],
    flexWrap: ['wrap', null, null, 'nowrap'],
    justifyContent: ['center', null, null, 'space-between'],
  },
};

const HEADLINE_CONTAINER_BASE_STYLES: ThemeUIStyleObject = {
  flexDirection: 'column',
  mx: [0, 6, 6],
};
const HEADLINE_STYLES: ThemeUIStyleObject = { textAlign: 'center', mb: 0 };
const BRAND_BELT_STYLES: ThemeUIStyleObject = {
  textAlign: 'center',
};

export const getStyles = ({
  variant,
  noContainer,
  pt,
  pb,
}: {
  variant: BrandGalleryVariant;
  noContainer: boolean;
  pt: useContainerPaddingReturn[number];
  pb: useContainerPaddingReturn[number];
}): Record<
  'headlineContainer' | 'headline' | 'brandBelt',
  ThemeUIStyleObject
> => ({
  headlineContainer: {
    ...HEADLINE_CONTAINER_BASE_STYLES,
    mb: [4, variant === 'CONDENSED' ? '16px' : '40px'],
    pt: noContainer && pt,
  },
  headline: HEADLINE_STYLES,
  brandBelt: {
    ...BRAND_BELT_STYLES,
    ...brandVariantStyles[variant],
    pb: noContainer && pb,
  },
});
