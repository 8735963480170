/** @jsx jsx */
import { jsx } from 'theme-ui';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Fragment } from 'react';
import { BrandGalleryVariant } from '../types';

type PropTypes = {
  logo: any;
  name: string;
  variant: BrandGalleryVariant;
};

const BrandImage: React.FC<PropTypes> = ({ logo, name, variant }) => {
  const gatsbyImageData = logo?.gatsbyImageData;
  const altDescription = logo?.description;
  const imgUrl = logo?.file?.url;
  const desktopHeight = '56px';
  const smallScreenHeight = '50px';

  return (
    <Fragment>
      {variant === 'BRAND_BELT' ? (
        <div
          sx={{
            flex: ['0 1 34%', '0 1 26%', null, '0 1 auto'],
            ':last-of-type': { mr: [2, null, null, 0] },
            ':first-of-type': { ml: [2, null, null, 0] },
            ':not(:first-of-type)': { ml: [2, null, null, 0] },
            ':not(:last-of-type)': { mr: [2, null, null, 0] },
            my: [2, null, null, 0],
            height: [smallScreenHeight, null, desktopHeight],
          }}
        >
          <img
            src={imgUrl}
            alt={altDescription || name}
            sx={{
              height: '100%',
            }}
          />
        </div>
      ) : (
        // This will not work for SVGs - if you run into an issue in the future with authors chosing to use SVGs instead of PNGs, change this to img element.
        // Not doing that until it becomes an issue so we can take advantage of fade-in and GatsbyImage performance benefits.
        <GatsbyImage
          image={gatsbyImageData}
          alt={altDescription || name}
          imgStyle={{
            objectFit: 'contain',
          }}
        />
      )}
    </Fragment>
  );
};

export default BrandImage;
