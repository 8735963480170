/** @jsx jsx */
import { Fragment, useMemo } from 'react';
import { jsx } from 'theme-ui';
import { Flex, Themed } from 'theme-ui';
import { Container, Link } from '@sprinklr/shared-lib';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';
import BrandImage from './brandImage';
import { assignDefaultProp } from '@sprinklr/shared-lib/utils/assignDefaultProp';
import { getStyles } from './styles';
import { Props } from './types';

const ConditionalLinkWrapper: React.FC<{ children: any; link: string }> = ({
  link,
  children,
}) => {
  return (
    <Fragment>
      {!!link ? (
        <Link href={`/${link.replace(/^\/|\/$/g, '')}/`}>{children}</Link>
      ) : (
        children
      )}
    </Fragment>
  );
};

const BrandGalleryTemplate: React.FC<Props> = ({
  sectionId,
  headline,
  headlineSize,
  redirectUrl,
  brands,
  topMargin,
  bottomMargin,
  fullWidth,
  ...props
}) => {
  const noContainer = assignDefaultProp(props.noContainer, false);
  const variant = assignDefaultProp(props.variant, 'DEFAULT');
  const padding = { top: topMargin, bottom: bottomMargin };
  const [pt, pb] = useContainerPadding(padding);
  const outerContainerSx = { pt, pb };
  const brandsSubset = variant !== 'BRAND_BELT' ? brands : brands?.slice(0, 5);
  const ThemedHeadline = headlineSize ? Themed[headlineSize] : Themed.h2;

  const styles = useMemo(
    () => getStyles({ variant, noContainer, pt, pb }),
    [variant, noContainer, pt, pb],
  );

  const BrandsHeadline = () => (
    <Fragment>
      {headline && (
        <Flex sx={styles.headlineContainer}>
          {headline && (
            <ThemedHeadline sx={styles.headline}>{headline}</ThemedHeadline>
          )}
        </Flex>
      )}
      <ConditionalLinkWrapper link={redirectUrl}>
        <Flex sx={styles.brandBelt}>
          {brandsSubset?.map(brand => {
            return <BrandImage key={brand.id} variant={variant} {...brand} />;
          })}
        </Flex>
      </ConditionalLinkWrapper>
    </Fragment>
  );
  return noContainer ? (
    <BrandsHeadline />
  ) : (
    <Container id={sectionId} sx={outerContainerSx} fullWidth={fullWidth}>
      <BrandsHeadline />
    </Container>
  );
};

export default BrandGalleryTemplate;
